import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import NavBar from './NavBar/index';
import ClientNavBar from './NavBar/ClientNavBar';
import TopBar from './TopBar';
import useIsClientView from 'src/hooks/useIsClientView';
import useHashReference from 'src/hooks/useHashReference';
import * as LanternApi from 'src/utilities/LanternApiClient';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 32,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'hidden',
  },
}));

const DashboardLayout = ({ children, routes }) => {
  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(true);
  const [parentClient, setParentClient] = useState({});
  const isClientView = useIsClientView();
  const hashReference = useHashReference();

  useEffect(() => {
    getParentClient();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getParentClient = async () => {
    if (isClientView) {
      await LanternApi.getParentClientByHashReference(hashReference).then((response) => {
        if (response.success) {
          setParentClient(response.body);
        }
      });
    }
  };

  return (
    <div className={classes.root}>
      <TopBar onNavOpen={() => setNavOpen(!isNavOpen)} />
      {isClientView ? (
        <ClientNavBar openMobile={true} parentClientName={parentClient?.name} />
      ) : (
        <NavBar onNavClose={() => setNavOpen(false)} routes={routes} openMobile={isNavOpen} />
      )}
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
