import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { ENVIRONMENT } from './utilities/api-config';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const environnement = ENVIRONMENT;
var key = '';

if (environnement == 'Production') {
  key = 'dcc2c00d-3177-456d-8b1f-c65cec8bfe04';
} else if (environnement == 'Test') {
  key = 'd4032038-fc27-4e75-b33a-6c0e72065509';
}
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: `${key}`,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
