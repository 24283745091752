import { graphConfig } from './config';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return await fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function callMsGraphGroups(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  var response = await fetch(graphConfig.graphUserSecurityGroupsEndpoint, options);

  var data = await response.json();

  if (data['@odata.nextLink']) {
    let nextPageData = await fetchAllPages(data['@odata.nextLink'], options);
    data.value = [...data.value, ...nextPageData.value];
  }

  return data;
}

async function fetchAllPages(url, options) {
  let response = await fetch(url, options);
  let data = await response.json();

  if (data['@odata.nextLink']) {
    let nextPageData = await fetchAllPages(data['@odata.nextLink'], options);
    data.value = [...data.value, ...nextPageData.value];
  }

  return data;
}

export async function callMsGraphGetUserPhoto(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return await fetch(graphConfig.graphUserPhotoEndpoint, options)
    .then((response) => response.blob().then((blob) => URL.createObjectURL(blob)))
    .catch((error) => console.log(error));
}
