import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

const AuthGuard = ({ children, route }) => {
  if (!route.accessible) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  route: PropTypes.object,
};

export default AuthGuard;
