import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button } from '@mui/material';
import jssPreset from '@mui/styles/jssPreset';
import StylesProvider from '@mui/styles/StylesProvider';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Router } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/AuthContext';
import useSettings from 'src/hooks/useSettings';
import Routes from 'src/routes';
import { createTheme } from 'src/theme';
import { ClientViewProvider } from './contexts/ClientViewContext';
import { HashReferenceProvider } from './contexts/HashReferenceContext';
import { FeatureFlagsProvider } from './contexts/FeatureFlagsContext';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App = () => {
  const { settings } = useSettings();
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* I added pointerEvents according to a github issue that made buttons unclickable
            https://github.com/iamhosseindhv/notistack/issues/394 */}
              <SnackbarProvider
                dense
                maxSnack={5}
                style={{ pointerEvents: 'all' }}
                ref={notistackRef}
                action={(key) => (
                  <Button color="inherit" onClick={onClickDismiss(key)}>
                    Dismiss
                  </Button>
                )}
              >
                <Router history={history}>
                  <ClientViewProvider>
                    <HashReferenceProvider>
                      <AuthProvider>
                        <FeatureFlagsProvider>
                          <GlobalStyles />
                          <ScrollReset />
                          <Routes />
                        </FeatureFlagsProvider>
                      </AuthProvider>
                    </HashReferenceProvider>
                  </ClientViewProvider>
                </Router>
              </SnackbarProvider>
            </LocalizationProvider>
          </StylesProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
