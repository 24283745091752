import React, { createContext, useState } from 'react';
import { getQueryParams } from 'src/utilities/Utilities.js';

const getHashReference = () => getQueryParams().get('hashReference');

const HashReferenceContext = createContext(getHashReference());

export const HashReferenceProvider = ({ children }) => {
  const [hashReference] = useState(getHashReference());

  return <HashReferenceContext.Provider value={hashReference}>{children}</HashReferenceContext.Provider>;
};

export default HashReferenceContext;
