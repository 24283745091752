import React from 'react';
import { Typography, Grid } from '@mui/material';

const NoAlertsDisplay = () => {
  return (
    <Grid container justifyContent="center">
      <Typography variant="h5" color="textPrimary">
        No new messages. Have a great day; you can do this!
      </Typography>
      <img style={{ backgroundColor: 'white', marginTop: '10px' }} src={'/static/images/applicationIcons/happyCupcake.png'} />
    </Grid>
  );
};

export default NoAlertsDisplay;
