import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { THEMES } from 'src/constants';
import * as LanternApi from 'src/utilities/LanternApiClient';
import { enqueueSnackbar } from 'notistack';

const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.DARK,
};

export const restoreSettings = async () => {
  var response = await LanternApi.getUserSettings();

  if (response.success) {
    return response.body;
  } else {
    var secondResponse = await LanternApi.initializeUserSettings();

    if (secondResponse.success) {
      return secondResponse.body;
    } else {
      return defaultSettings;
    }
  }
};

export const storeSettings = async (updatedSettings) => {
  var response = await LanternApi.updateUserSettings(updatedSettings);

  if (response.success) {
    enqueueSnackbar('Settings saved.', {
      variant: 'success',
    });
  } else {
    enqueueSnackbar('Failed to save settings.', {
      variant: 'error',
    });
  }
};

const SettingsContext = createContext({
  settings: defaultSettings,
  saveSettings: () => {},
});

export const SettingsProvider = ({ settings, children }) => {
  const [currentSettings, setCurrentSettings] = useState(settings || defaultSettings);

  const handleSaveSettings = (newSettings = {}) => {
    const mergedSettings = _.merge({}, currentSettings, newSettings);

    setCurrentSettings(mergedSettings);
    storeSettings(mergedSettings);
  };

  useEffect(async () => {
    const restoredSettings = await restoreSettings();

    if (restoredSettings) {
      setCurrentSettings(restoredSettings);
    }
  }, []);

  useEffect(() => {
    document.dir = currentSettings.direction;
  }, [currentSettings]);

  return (
    <SettingsContext.Provider
      value={{
        settings: currentSettings,
        saveSettings: handleSaveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
