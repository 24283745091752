import React from 'react';
import { IconButton, ListItemSecondaryAction, Tooltip } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InventoryIcon from '@mui/icons-material/Inventory';

const ArchiveAlertActions = ({ alert, clickHandler }) => {
  return (
    <ListItemSecondaryAction style={{ transform: 'none', marginTop: '10px' }}>
      <Tooltip title="Archive Alert">
        <IconButton onClick={() => clickHandler(alert.id, false)} size="large">
          <CancelIcon />
        </IconButton>
      </Tooltip>
      {alert.shouldAllowArchiveForAllUsers && !alert.archiveForAllUsers && (
        <Tooltip title="Archive Alert for All">
          <IconButton onClick={() => clickHandler(alert.id, true)} size="large">
            <InventoryIcon />
          </IconButton>
        </Tooltip>
      )}
    </ListItemSecondaryAction>
  );
};

export default ArchiveAlertActions;
