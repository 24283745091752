import React from 'react';
import { List, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NoAlertsDisplay from 'src/layouts/DashboardLayout/TopBar/AlertsComponents/NoAlertsDisplay';
import AlertListItem from 'src/layouts/DashboardLayout/TopBar/AlertsComponents/AlertListItem';

const useStyles = makeStyles((theme) => ({
  root: {
    '& a': {
      color: theme.palette.text.secondary,
    },
  },
}));

const AlertsContainer = ({ alerts, clickHandler, actionsComponent }) => {
  const classes = useStyles();

  return (
    <>
      {alerts.length > 0 ? (
        <List className={classes.root}>
          {alerts.map((alert) => (
            <>
              <AlertListItem
                key={`${alert.id}+${alert.message}`}
                alert={alert}
                actionsComponent={actionsComponent}
                clickHandler={clickHandler}
              />
              <Divider component="li" />
            </>
          ))}
        </List>
      ) : (
        <NoAlertsDisplay />
      )}
    </>
  );
};

export default AlertsContainer;
