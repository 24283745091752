import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import { PublicClientApplication } from '@azure/msal-browser';
import useIsClientView from 'src/hooks/useIsClientView';
import { getUserInfo } from 'src/utilities/msalUtilities';
import { msalConfig } from 'src/config';

const instance = new PublicClientApplication(msalConfig);

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null,
  hasGroup: true,
  hasPermission: () => false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state, isInitialized: false };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  loginWithRedirect: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const isClientView = useIsClientView();

  useEffect(async () => {
    const key = Object.keys(localStorage).filter((key) => localStorage.getItem(key).startsWith('{"failedRequests'))[0];
    const telemetryString = localStorage.getItem(key);
    var serverTelemetry = JSON.parse(telemetryString);

    if (serverTelemetry?.failedRequests.length > 0) {
      localStorage.clear();
    }
    const initialize = async () => {
      if (state.user || isClientView) {
        dispatch({
          payload: {
            isAuthenticated: true,
          },
        });
        return;
      }
      try {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: state.user,
          },
        });
        await getUserInfo().then(async (response) => {
          await dispatch({
            type: 'LOGIN',
            payload: {
              user: response,
            },
          });
        });
      } catch (error) {
        await handleLogin();
      }
    };
    await initialize();
  }, []);

  const handleLogin = async () => {
    await instance.initialize();

    await instance.handleRedirectPromise();

    if (state.isInitialized) {
      if (!state.user) {
        await instance.loginRedirect();
      }
      dispatch({
        payload: {
          isAuthenticated: true,
        },
      });
    }
  };

  const handleLogout = async () => {
    await instance.initialize();

    await instance.handleRedirectPromise();

    await instance.logoutRedirect({
      postLogoutRedirectUri: '/',
    });
    dispatch({
      payload: {
        isAuthenticated: false,
        user: null,
      },
    });
  };

  const hasPermission = (role) => {
    let hasPerm = false;

    if (state.user && state.user?.groups) {
      hasPerm = state.user.groups.indexOf(role) != -1 || state.user.groups.indexOf('Lantern Admin') != -1;
    }

    return hasPerm;
  };

  if (!state.user && !isClientView) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginWithRedirect: () => handleLogin(),
        logout: () => handleLogout(),
        hasPermission,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
