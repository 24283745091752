import React from 'react';
import { IconButton, ListItemSecondaryAction, Tooltip } from '@mui/material';
import UndoIcon from '@mui/icons-material/Undo';

const UnarchiveAlertActions = ({ alert, clickHandler }) => {
  return (
    <ListItemSecondaryAction style={{ transform: 'none' }}>
      <Tooltip title="Unarchive Alert">
        <IconButton onClick={() => clickHandler(alert.id)} size="large">
          <UndoIcon />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  );
};

export default UnarchiveAlertActions;
