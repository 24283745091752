import React, { createContext, useState } from 'react';
import * as LanternApi from 'src/utilities/LanternApiClient';

const FeatureFlagsContext = createContext({});

export const FeatureFlagsProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState({ featureFlagsLoaded: false });

  if (!contextValue.featureFlagsLoaded) {
    LanternApi.getFeatureFlags().then((flags) => {
      setContextValue({
        featureFlags: flags.body,
        featureFlagsLoaded: true,
      });
    });
  }

  return <FeatureFlagsContext.Provider value={contextValue}>{children}</FeatureFlagsContext.Provider>;
};

export default FeatureFlagsContext;
