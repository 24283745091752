import React, { useState, useRef, useEffect } from 'react';
import { Badge, IconButton, Popover, Tab, Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as LanternApi from 'src/utilities/LanternApiClient.jsx';
import { useInterval } from 'src/hooks/useInterval';
import AlertsContainer from 'src/layouts/DashboardLayout/TopBar/AlertsComponents/AlertsContainer';
import ArchiveAlertActions from 'src/layouts/DashboardLayout/TopBar/AlertsComponents/ArchiveAlertActions';
import UnarchiveAlertActions from 'src/layouts/DashboardLayout/TopBar/AlertsComponents/UnarchiveAlertActions';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 500,
    padding: theme.spacing(2),
  },
  root: {
    '& a': {
      color: theme.palette.text.secondary,
    },
    paddingLeft: '0',
    paddingRight: '0',
  },
}));

const ALERTS_REFRESH_INTERVAL = 60000; //1 minute

const Alerts = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [alerts, setAlerts] = useState([]);
  const [archivedAlerts, setArchivedAlerts] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState('0');

  const handleValueChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserAlerts = async () => {
    const response = await LanternApi.getUserAlerts();

    if (response.success) {
      setAlerts(response.body.filter((alert) => alert.isArchived === false));
      setArchivedAlerts(response.body.filter((alert) => alert.isArchived === true));
    }
  };

  useInterval(getUserAlerts, ALERTS_REFRESH_INTERVAL);

  const handleArchiveClick = async (alertId, archiveForAllUsers) => {
    const response = archiveForAllUsers
      ? await LanternApi.archiveAlertForAllUsers(alertId)
      : await LanternApi.archiveAlert(alertId);

    let alertToArchive = alerts.find((alert) => alert.id === alertId);

    if (archiveForAllUsers) {
      alertToArchive.archiveForAllUsers = true;
    }

    if (response.success) {
      setAlerts(alerts.filter((alert) => alert.id !== alertId));
      setArchivedAlerts([...archivedAlerts, alertToArchive]);
    }
  };

  const handleUnarchiveClick = async (alertId) => {
    const response = await LanternApi.unarchiveAlert(alertId);

    if (response.success) {
      setAlerts([...alerts, archivedAlerts.find((alert) => alert.id === alertId)]);
      setArchivedAlerts(archivedAlerts.filter((alert) => alert.id !== alertId));
    }
  };

  useEffect(() => {
    getUserAlerts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <IconButton style={{ color: 'white' }} onClick={handleOpen} ref={ref} size="large" disableRipple>
        <Badge badgeContent={alerts.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" color="textPrimary">
              Notifications
            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="close" onClick={handleClose} size="large" disableRipple>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <TabContext value={value}>
          <TabList onChange={handleValueChange} aria-label="simple tabs example">
            <Tab label="Unread" value="0" />
            <Tab label="Archived" value="1" />
          </TabList>
          <TabPanel className={classes.root} value="0">
            <AlertsContainer alerts={alerts} clickHandler={handleArchiveClick} actionsComponent={ArchiveAlertActions} />
          </TabPanel>
          <TabPanel className={classes.root} value="1">
            <AlertsContainer
              alerts={archivedAlerts}
              clickHandler={handleUnarchiveClick}
              actionsComponent={UnarchiveAlertActions}
            />
          </TabPanel>
        </TabContext>
      </Popover>
    </>
  );
};

export default Alerts;
