import React from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';
import { Box, Drawer, List, ListSubheader } from '@mui/material';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import CableIcon from '@mui/icons-material/Cable';
import EditIcon from '@mui/icons-material/Edit';
import FlagIcon from '@mui/icons-material/Flag';
import HubIcon from '@mui/icons-material/Hub';
import ImageIcon from '@mui/icons-material/Image';
import PersonIcon from '@mui/icons-material/Person';
import SendIcon from '@mui/icons-material/Send';
import SettingsIcon from '@mui/icons-material/Settings';
import StorageIcon from '@mui/icons-material/Storage';
import TuneIcon from '@mui/icons-material/Tune';
import UpdateIcon from '@mui/icons-material/Update';
import UploadIcon from '@mui/icons-material/Upload';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import useAuth from 'src/hooks/useAuth';
import { renderNavItems } from 'src/utilities/NavBarUtilities';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '5px',
    '&:hover': {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      transitionDelay: '200ms',
    },
  },
}));

const getSections = (routes) => {
  const sections = [
    {
      subheader: 'Digital Content Management',
      items: [
        {
          title: 'Content Initiation Requests',
          icon: SendIcon,
          route: routes.app.products.list.route,
        },
        {
          title: 'Copywriting',
          icon: EditIcon,
          route: routes.app.content.list.route,
        },
        {
          title: 'Digital Assets',
          icon: ImageIcon,
          route: routes.app.assetManager.route,
        },
        {
          title: 'Enhanced Content',
          icon: AutoFixNormalIcon,
          route: routes.app.enhancedContent.route,
        },
        {
          title: 'Keywording',
          icon: VpnKeyIcon,
          route: routes.app.quickKeywording.route,
          items: [
            {
              title: 'Quick Keywording',
              route: routes.app.quickKeywording.route,
            },
            {
              title: 'Keyword Manager',
              route: routes.app.keywordManager.route,
            },
          ],
        },
        {
          title: 'Configuration Settings',
          icon: TuneIcon,
          route: routes.app.clientConfiguration.route,
          items: [
            {
              title: 'Client Configuration',
              route: routes.app.clientConfiguration.route,
            },
            {
              title: 'Retailer Configuration',
              route: routes.app.retailerConfiguration.route,
            },
          ],
        },
      ],
    },
    {
      subheader: 'Ember Data Management',
      items: [
        {
          title: 'Ember Dataset Manager',
          icon: StorageIcon,
          route: routes.app.emberDatasetManager.route,
        },
        {
          title: 'Client Goals Tracker',
          icon: FlagIcon,
          route: routes.app.clientGoalsTracker.route,
        },
      ],
    },
    {
      subheader: 'Permissions',
      items: [
        {
          title: 'Permission Management',
          icon: PersonIcon,
          route: routes.app.permissions.route,
        },
      ],
    },
    {
      subheader: 'Financial Management',
      items: [
        {
          title: 'Forecast Roundtable Tool',
          icon: UpdateIcon,
          route: routes.app.forecastRoundtableTool.route,
        },
        {
          title: 'Forecast Roundtable Manager',
          icon: SettingsIcon,
          route: routes.app.forecastRoundtableManager.route,
        },
        {
          title: 'NetSuite-Foreman Mapping',
          icon: CableIcon,
          route: routes.app.netsuiteToForemanClientMappingTool.route,
        },
        {
          title: 'PBCS File Uploader',
          icon: UploadIcon,
          route: routes.app.pbcsFileUploader.route,
        },
      ],
    },
    {
      subheader: 'Integration',
      items: [
        {
          title: 'Client Attribution Tool',
          icon: HubIcon,
          route: routes.app.clientAttributionTool.route,
        },
      ],
    },
  ];

  return sections
    .map((section) => {
      section.items = section.items.filter((item) => item.route.accessible);
      return section;
    })
    .filter((section) => section.items.length);
};

export default function NavBar({ onNavClose, openMobile, routes }) {
  const classes = useStyles();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const content = (
    <Box marginTop="40px" height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        {getSections(routes).map((section) => (
          <List
            key={section.subheader}
            subheader={
              <ListSubheader disableSticky disableGutters sx={{ fontWeight: 'bold' }}>
                {section.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: section.items,
              pathname: location.pathname,
              expanded: openMobile,
            })}
            <Divider />
          </List>
        ))}
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      {isAuthenticated && (
        <div className={classes.root}>
          <Drawer
            anchor="left"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: openMobile,
              [classes.drawerClose]: !openMobile,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: openMobile,
                [classes.drawerClose]: !openMobile,
              }),
            }}
            open={openMobile}
            variant="permanent"
          >
            {content}
          </Drawer>
        </div>
      )}
    </React.Fragment>
  );
}
