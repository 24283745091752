import React, { createContext, useState } from 'react';

const getIsClientView = () => window.location.href.includes('client-review');

const ClientViewContext = createContext(getIsClientView());

export const ClientViewProvider = ({ children }) => {
  const [isClientView] = useState(getIsClientView());

  return <ClientViewContext.Provider value={isClientView}>{children}</ClientViewContext.Provider>;
};

export default ClientViewContext;
