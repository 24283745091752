import { AppBar, Box, Button, Hidden, IconButton, SvgIcon, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Menu as MenuIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';
import LanternLogo from 'src/components/LanternLogo';
import { THEMES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import {
  ENVIRONMENT,
  isProductionEnvironment,
  isProductionApi,
  isTestApi,
  isDevelopmentApi,
} from '../../../utilities/api-config';
import Account from './Account';
import Settings from './Settings';
import Alerts from './Alerts';
import useIsClientView from 'src/hooks/useIsClientView';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(isProductionEnvironment()
      ? {
          ...(theme.name === THEMES.LIGHT
            ? {
                backgroundColor: theme.palette.header.dark,
              }
            : {}),
          ...(theme.name === THEMES.DARK
            ? {
                backgroundColor: theme.palette.background.default,
              }
            : {}),
        }
      : {
          ...(isProductionApi()
            ? {
                backgroundColor: theme.palette.warning.main,
              }
            : {
                backgroundColor: theme.palette.text.secondary,
              }),
        }),
  },
  toolbar: {
    minHeight: 52,
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  logo: {
    marginTop: '4px',
    marginRight: 2,
  },
}));

const TopBar = ({ className, onNavOpen, ...rest }) => {
  const classes = useStyles();
  const { isAuthenticated, loginWithRedirect } = useAuth();
  const isClientView = useIsClientView();

  const getTopBarText = () => {
    if (isProductionEnvironment()) {
      return 'Lantern';
    }

    return 'Lantern ' + ENVIRONMENT + ' - ' + 'API Environment: ' + getApiEnvironment();
  };

  const getApiEnvironment = () => {
    if (isProductionApi()) {
      return 'Production';
    } else if (isTestApi()) {
      return 'Test';
    } else if (isDevelopmentApi()) {
      return 'Local Development';
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar disableGutters className={classes.toolbar}>
        {!isClientView && (
          <IconButton style={{ marginRight: '20px', color: 'white' }} onClick={onNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box pr={2} className={classes.logo}>
            {!isClientView ? (
              <RouterLink to="/">
                <LanternLogo environment={getApiEnvironment} />
              </RouterLink>
            ) : (
              <LanternLogo environment={getApiEnvironment} />
            )}
          </Box>
          <Box p={1}>
            <Typography variant="h4" color="white">
              {getTopBarText()}
            </Typography>
          </Box>
        </Box>
        <Box ml={2} flexGrow={1} />
        {!isClientView && isAuthenticated && <Alerts />}
        <Settings />
        {!isClientView && isAuthenticated && (
          <Box ml={2}>
            <Account />
          </Box>
        )}
        {!isClientView && !isAuthenticated && (
          <Box ml={2}>
            <Button onClick={() => loginWithRedirect()} color="inherit">
              Login
            </Button>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onNavOpen: () => {},
};

export default TopBar;
