import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Page from 'src/components/Page';
import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
    filter: theme.name === THEMES.LIGHT ? 'invert(.1)' : 'invert(.9)',
  },
}));

const ErrorView = ({ title, text }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth="lg">
        <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'} color="textPrimary">
          {text}
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img alt="Under development" className={classes.image} src="/static/images/shopping_cart.svg" />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button color="secondary" component={RouterLink} to="/" variant="outlined">
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default ErrorView;
