import { List } from '@mui/material';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem';
import { matchPath } from 'react-router-dom';

export function renderNavItems({ items, pathname, expanded, depth = 0 }) {
  return (
    <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth, expanded }), [])}</List>
  );
}

export function reduceChildRoutes({ acc, pathname, item, depth, expanded }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.route.path,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        expanded={expanded}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else if (item.route.accessible) {
    acc.push(
      <NavItem
        depth={depth}
        href={item.route.path}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        expanded={expanded}
      />
    );
  }

  return acc;
}
