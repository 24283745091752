let backendHost;
let environment;

const demoHostnames = ['lantern-frontend-cdn-demo.azureedge.net'];
const testHostnames = ['lantern-test.harvestgroup.com', 'lantern-frontend-cdn-test.azureedge.net'];
const prodHostnames = ['lantern.harvestgroup.com', 'lantern-frontend-cdn-prod.azureedge.net'];

const localBackendHost = 'http://localhost';
const testBackendHost = 'https://lantern-api-test.harvestgroup.com';
const prodBackendHost = 'https://lantern-api.harvestgroup.com';

const hostname = window && window.location && window.location.hostname;
const apiEnv = (process.env.REACT_APP_API_ENV || '').toLowerCase();

export const isProductionEnvironment = () => {
  return prodHostnames.includes(hostname);
};

export const isTestEnvironment = () => {
  return testHostnames.includes(hostname) || apiEnv === 'test';
};

export const isDemoEnvironment = () => {
  return demoHostnames.includes(hostname) || apiEnv === 'demo';
};

export const isLocalEnvironment = () => {
  return !isProductionEnvironment() && !isTestEnvironment() && !isDemoEnvironment();
};

if (isDemoEnvironment()) {
  backendHost = testBackendHost;
  environment = 'Demo';
} else if (isTestEnvironment()) {
  backendHost = testBackendHost;
  environment = 'Test';
} else if (isProductionEnvironment()) {
  backendHost = prodBackendHost;
  environment = 'Production';
} else {
  backendHost = localBackendHost;
  environment = 'Local';
}

export const API_ROOT = backendHost;
export const ENVIRONMENT = environment;

export const isProductionApi = () => {
  return API_ROOT === prodBackendHost;
};

export const isTestApi = () => {
  return API_ROOT === testBackendHost;
};

export const isDevelopmentApi = () => {
  return API_ROOT === localBackendHost;
};
