import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Drawer, List } from '@mui/material';
import { Image as ImageIcon, Home as HomeIcon } from 'react-feather';
import NavItem from 'src/layouts/DashboardLayout/NavBar/NavItem';
import useHashReference from 'src/hooks/useHashReference';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(8),
  },
}));

export default function ClientNavBar({ openMobile, parentClientName }) {
  const classes = useStyles();
  const hashReference = useHashReference(parentClientName);
  const { featureFlags, featureFlagsLoaded } = useFeatureFlags();

  const content = (
    <Box marginTop="40px" height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <List key="Client Tools">
            <NavItem
              depth={0}
              href={`/client-review/client/${parentClientName}?hashReference=${hashReference}`}
              icon={HomeIcon}
              key="Content Review"
              title="Content Review"
              expanded={openMobile}
            />
            <NavItem
              depth={0}
              href={`/client-review/client/${parentClientName}/assetManager/a?hashReference=${hashReference}`}
              icon={ImageIcon}
              key="Asset Manager"
              title="Asset Manager"
              expanded={openMobile}
            />
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const showClientNavBar = () => {
    return featureFlagsLoaded && featureFlags && !featureFlags.disableAssetManager;
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        {showClientNavBar() && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: openMobile,
              [classes.drawerClose]: !openMobile,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: openMobile,
                [classes.drawerClose]: !openMobile,
              }),
            }}
          >
            {content}
          </Drawer>
        )}
      </div>
    </React.Fragment>
  );
}
