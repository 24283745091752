import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: '939e57ac-47f9-4ff5-a85f-22007f12856d',
    authority: 'https://login.microsoftonline.com/52934c9b-912c-4480-b2bc-72fee70477bb/',
    redirectUri: window?.location?.origin,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where cache will be stored
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const loginRequestOpenId = {
  scopes: ['api://939e57ac-47f9-4ff5-a85f-22007f12856d/access'],
};
export const loginRequest = {
  scopes: ['User.Read'],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUserSecurityGroupsEndpoint: 'https://graph.microsoft.com/v1.0/me/memberOf',
  graphUserPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
};
