export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const ENVIRONMENTS = {
  PRODUCTION: 'Production',
  DEMO: 'Demo',
  TEST: 'Test',
  LOCAL: 'Local',
};

// Snowflake editing tool table names
export const COMMODITY_CUSTOM_ATTRIBUTION_TABLE = 'Commodity Custom Attribution';
export const CUSTOM_ATTRIBUTION_TABLE = 'Item Attribution';
export const ASIN_GTIN_MAPPING_TABLE = 'ASIN - GTIN';
export const CASE_UPC_CONSUMER_UPC_MAPPING_TABLE = 'Case UPC - Consumer UPC';
export const CAMPAIGN_ATTRIBUTION_TABLE_ID = 32;
export const API_PUBLISH_RETAILER_IDS = [1, 6];
export const WALMART_RETAILER_ID = 1;
export const AMAZON_RETAILER_ID = 6;
export const TARGET_RETAILER_ID = 7;
export const SAMSCLUB_RETAILER_ID = 2;
export const ALL_RETAILERS_NAME = 'All Retailers';
export const AMAZON_ACCOUNT_TYPES = ['seller', 'vendor'];

export const EXCEL_CONTENT_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
