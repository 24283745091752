import React from 'react';
import { ListItem, ListItemText, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Cancel from '@mui/icons-material/Cancel';
import CampaignIcon from '@mui/icons-material/Campaign';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: '10px',
  },
  root: { paddingLeft: '0', paddingRight: '0' },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AlertListItem = ({ alert, clickHandler, actionsComponent }) => {
  const ActionsComponent = actionsComponent;
  const classes = useStyles();
  const alertTypes = {
    Failure: <Cancel className={classes.icon} style={{ color: 'red', fontSize: 45 }} />,
    Success: <CheckCircleIcon className={classes.icon} style={{ color: 'green', fontSize: 45 }} />,
    Announcement: <CampaignIcon className={classes.icon} style={{ color: '#B0C4DE', fontSize: 45 }} />,
    Warning: <InfoIcon className={classes.icon} style={{ color: 'orange', fontSize: 45 }} />,
  };

  return (
    <ListItem className={classes.root}>
      <Grid container>
        <Grid item sx={{ width: '15%' }} className={classes.iconContainer}>
          {alertTypes[alert.type] ?? <InfoIcon className={classes.icon} style={{ color: 'gray', fontSize: 45 }} />}
        </Grid>
        <Grid item sx={{ width: '70%' }}>
          <ListItemText
            primary={alert.title}
            secondary={
              <>
                <div dangerouslySetInnerHTML={{ __html: alert.message }} />
                <div>{new Date(alert.createdOn).toLocaleString()}</div>
              </>
            }
          />
        </Grid>
        <Grid item sx={{ width: '15' }}>
          <ActionsComponent alert={alert} clickHandler={clickHandler} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default AlertListItem;
